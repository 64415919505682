import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Cta from "../components/cta"
import Seo from "../components/seo"
import scrollTo from 'gatsby-plugin-smoothscroll'
import TestimonialSection from "../components/testimonials-section"
import Collapsible from 'react-collapsible';
import ChevronDown from "../components/svg/chevronDown"
import ArrowRight from "../components/svg/arrowRight"

const AiLLMPage = () => (
    <Layout>
        <Seo 
            title="Artificial Intelligence LLM Developers Sydney Canberra, Australia" 
            description="Launch Lab offer artifical intelligence development in Sydney and Canberra. We leverage large language models like ChatGPT and Mistral, via AWS Bedrock."
            pathname="/artificial-intelligence-developers/"
            serviceType="AI and LLM development"
        /> 

        <section role="main" className="bg-purple with-pad-sm white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="100"
                      data-sal-duration="400"
                      >Artificial Intelligence Developers Sydney &amp; Canberra</h1>
                      <h2 className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="300"
                      data-sal-duration="400"
                      >
                          We leverage large language models (LLMs) like ChatGPT and Mistral, via AWS Bedrock, to automate tasks
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3>Leverage LLMs in your business</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>
                                Launch Lab delivers <strong>artificial intelligence</strong> solutions by leveraging leading foundational large language models.
                            </p>
                            <p>
                                We've worked with the ChatGPT API to deliver solutions for <a href="/startup-web-application-development/">startups</a> and for our own internal needs.
                            </p>
                            <p>
                                Where data privacy is needed, we've used LLMs like <a href="https://mistral.ai/">Mistral.ai</a>, within <a href="https://aws.amazon.com/bedrock/">AWS Bedrock</a>, to automate tasks and improve user experience within software we've custom developed.
                            </p>
                            <p>
                                We also have <a href="/machine-learning-developers-sydney/">machine learning and data science</a> experience.
                            </p>
                            <p>
                                If you're looking for an Australian based artificial intelligence developer we would love to discuss your requirements with you.
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                AI FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only work with foundational LLMs? <ChevronDown />
                                </>
                                }
                            >
                                <p>We are always interested in bespoke machine learning and AI projects, however the cost effectiveness of large language models for use by startups often can't be beaten.</p>
                                <p>If you have a bespoke AI project please reach out and we'll let you know if we're a good fit or not.</p>
                            </Collapsible>
                            <Collapsible
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you only offer AI development services to startups? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. Whether you're a startup founder or from an established business we can assist with ML and artificial intelligence development projects.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Are your AI developers based in Australia? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do you have a portfolio of your AI work? <ChevronDown />
                                </>
                                }
                            >
                                <p>You can read more in this blog post about our <Link to="/leveraging-llms-and-AI-tools-in-startups/">AI experience</Link></p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="We'd love to discuss how we can assist with your ML, AI or data science project."
        />
    </Layout>
)

export default AiLLMPage